<template>
  <v-skeleton-loader type="list-item-three-line" v-if="isLoading" />

  <v-card class="b-carousel" max-height="252px" rounded v-else>
    <v-img
      src="@/assets/carousel/banner.jpg"
      max-height="252px"
      width="100%"
      height="100%"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.b-carousel {
  img {
    object-fit: cover;
  }
}
</style>
